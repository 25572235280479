<template>
	<section id="dashboard-ecommerce">
		<b-row class="match-height">
			<b-col sm="12" md="12" lg="12" xl="12">
				<filters @selectedFilter="applyFiltersEvent($event)"></filters>
			</b-col>
		</b-row>
		<b-row class="match-height">
			<b-col lg="12">
				<b-row class="match-height">
					<b-col sm="12" md="12" lg="12" xl="12">
						<b-card no-body class="mb-0">
							<b-overlay :show="isFetchingRecordsFromServer" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">

								<b-card-header>
									<b-card-title>Lifetime Totals</b-card-title>
								</b-card-header>

								<b-table ref="refUserListTable" class="position-relative p-1" :items="tableSiteListArray" responsive :fields="tableColumnsArray" primary-key="id" show-empty empty-text="No records found">

									<template #cell(total_views)="data">
										<feather-icon v-if="!data.item.total_views" icon="LoaderIcon" size="18" class="spinner" />
										<span v-if="data.item.total_views">{{ data.item.total_views }}</span>
									</template>

									<template #cell(total_calls)="data">
										<feather-icon v-if="!data.item.total_calls" icon="LoaderIcon" size="18" class="spinner" />
										<span v-if="data.item.total_calls">{{ data.item.total_calls }}</span>
									</template>

									<template #cell(total_clicks)="data">
										<feather-icon v-if="!data.item.total_clicks" icon="LoaderIcon" size="18" class="spinner" />
										<span v-if="data.item.total_clicks">{{ data.item.total_clicks }}</span>
									</template>

									<template #cell(total_socials)="data">
										<feather-icon v-if="!data.item.total_socials" icon="LoaderIcon" size="18" class="spinner" />
										<span v-if="data.item.total_socials">{{ data.item.total_socials }}</span>
									</template>
									<template #cell(total_emails)="data">
										<feather-icon v-if="!data.item.total_emails" icon="LoaderIcon" size="18" class="spinner" />
										<span v-if="data.item.total_emails">{{ data.item.total_emails }}</span>
									</template>
									<template #cell(total_phones)="data">
										<feather-icon v-if="!data.item.total_phones" icon="LoaderIcon" size="18" class="spinner" />
										<span v-if="data.item.total_phones">{{ data.item.total_phones }}</span>
									</template>

								</b-table>
								<b-row v-if="this.selectedFilters.selectedSite == '' && this.selectedFilters.sitesLength > 10 ">
									<b-col cols="12 mb-1 d-flex justify-content-center">
										<b-overlay :show="disabledLoadMoreButton" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">
											<b-button type="button" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="" @click="lodeMoreRecords" v-if="hideLoadMoreButton">
												Load More
											</b-button>
										</b-overlay>
									</b-col>
								</b-row>

							</b-overlay>
						</b-card>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
	</section>
</template>

<script>
	import {
		BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BLink, BOverlay, BButton, BTable
	} from "bootstrap-vue";
	import Ripple from 'vue-ripple-directive'

	import Filters from "./Filter.vue";
	import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

	export default {
		components: {
			BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BLink, BOverlay, BButton, BTable,
			Filters,
		},
		directives: {
			Ripple,
		},
		data() {
			return {
				isFetchingRecordsFromServer: false,
				statisticsFilterData: {},
				tableColumnsArray: [
					{ key: 'siteURL', label: 'Site URL' },
					{ key: 'total_views', label: 'Views' },
					{ key: 'total_calls', label: 'Calls' },
					{ key: 'total_clicks', label: 'Clicks' },
					{ key: 'total_socials', label: 'Social Conversions' },
					{ key: 'total_emails', label: 'Emails Collected' },
					{ key: 'total_phones', label: 'Phone Numbers Collected' },
				],
				tableSiteListArray: [],
				siteListArray: [],
				records_start: 0,
				selectedFilters: {},
				fetchSiteConversionsRequest: 0,
				disabledLoadMoreButton: false,
				hideLoadMoreButton: true,
			};
		},
		watch: {
			fetchSiteConversionsRequest(newVal) {
				this.disabledLoadMoreButton = newVal ? true : false;
			}
		},
		methods: {
			lodeMoreRecords() {
				let takenSites = _.take(_.drop(this.siteListArray, this.records_start), 10); /* GET ONLY 10 ROWS */
				if (_.isEmpty(takenSites)) {
					this.hideLoadMoreButton = false;
					return false;
				}
				this.records_start += 10;
				this.tableSiteListArray.push(...takenSites); /* ADD 10 ROWS INTO TABLE ARRAY */

				takenSites.forEach(siteObj => {
					this.fetchSiteConversions(siteObj);
				});
			},
			applyFiltersEvent(filterObject) {

				/* RESET LODE MORE RECORDS : START */
				this.siteListArray = [];
				this.tableSiteListArray = [];
				this.records_start = 0;
				/* RESET LODE MORE RECORDS : END */

				this.selectedFilters = filterObject; /* ADD FILERTS TO OBJECT */

				/* SET ALL SITES TO SITE ARRAY AND LOAD FIRST 10 RECORDS : START */
				this.siteListArray = _.map(filterObject.sites, function (obj) {
					obj.total_views = ''
					obj.total_calls = ''
					obj.total_clicks = ''
					obj.total_socials = ''
					obj.total_emails = ''
					obj.total_phones = ''
					return obj;
				});
				this.lodeMoreRecords();
				/* SET ALL SITES TO SITE ARRAY AND LOAD FIRST 10 RECORDS : END */

			},
			fetchSiteConversions(siteObj) {
				this.fetchSiteConversionsRequest++;
				this.isFetchingRecordsFromServer = true;
				this.$reportAPI.post('fetch-site-conversions', {
					'auth_id': this.$root.authUserObject.id ?? '',
					'site_id': siteObj.siteId ?? '',
					'date_type': this.selectedFilters.date_type ?? '',
					'date_type_custom_filter': this.selectedFilters.date_type_custom_filter ?? '',
				}).then((response) => {
					response = response.data;
					if (response.status) {
						this.tableSiteListArray = _.map(this.tableSiteListArray, (obj) => {
							if (obj.siteId == siteObj.siteId) {
								obj.total_views = response.all_totals.total_visitor;
								obj.total_calls = response.all_totals.total_call;
								obj.total_clicks = response.all_totals.total_click;
								obj.total_socials = response.all_totals.total_share;
								obj.total_emails = response.all_totals.total_email;
								obj.total_phones = response.all_totals.total_phone;
							}
							return obj;
						});
					} else {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Notification',
								icon: 'BellIcon',
								variant: 'danger',
								text: response.message,
							},
						});
					}
					this.fetchSiteConversionsRequest--;
					this.isFetchingRecordsFromServer = false;
				});
			}
		},
		created() { },
		setup() { },
	};
</script>