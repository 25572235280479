<template>
	<b-card no-body class="card-statistics">
		<b-overlay :show="isFetchingRecordsFromServer" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">
			<b-card-header>
				<b-card-title>Filters</b-card-title>
			</b-card-header>
			<b-card-body>
				<b-row>
					<b-col class="m-50" xl="2" sm="12">
						<Select2 v-model="dateTypeValue" :options="dateTypeOptions" @change="selectDateTypeChangeEvent($event)" />
					</b-col>
					<b-col class="m-50" xl="3" sm="12" v-if="dateTypeValue == 'custom_date'">
						<VueCtkDateTimePicker id="customDateTypeFilter" v-model="customDateTypeFilter" :overlay="true" :noShortcuts="true" :range="true" :onlyDate="true" :time="false" format="MM/DD/YYYY" formatted="ll" @is-hidden="changeCustomDateEvent" />
					</b-col>
					<b-col class="m-50" xl="3" sm="12">
						<Select2 v-model="selectedSite" :options="userSitesDropDownArray" @change="selectUserSiteChangeEvent($event)" placeholder="Select Site" :settings="{ allowClear: true }"/>
					</b-col>
				</b-row>
			</b-card-body>
		</b-overlay>
	</b-card>
</template>
<script>
	import {
		BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BLink, BOverlay
	} from 'bootstrap-vue';

	import dateTypeListOptions from "@/libs/dateTypeListOptions";

	export default {
		components: {
			BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BAvatar, BMediaAside, BMediaBody, BOverlay
		},
		props: {
		},
		data() {
			return {
				isFetchingRecordsFromServer: false,
				dateTypeValue: 'today',
				customDateTypeFilter: '',
				userSitesDropDownArray: [],
				userSitesArray: [],
				filterSitesArray: [],
				selectedSite: this.$root.appSiteObject.siteId,
			}
		},
		beforeCreate: function () {
		},
		watch: {
		},
		created() {
			this.getDefaultFilterElements(); /* FETCH FILTER FROM VUEX */
			this.fetchUserSites();
		},
		mounted() {
		},
		computed: {
		},
		methods: {
			fetchUserSites() {
				this.isFetchingRecordsFromServer = true;
				this.$globalAPI.get('site/list', {
					status: 1,
					user_id: this.$root.authUserObject?.id
				}).then((response) => {
					response = response.data;
					if (response.status) {
						let sortedSitesArray = _.sortBy(response.sites, "siteURL");
						for (const site of sortedSitesArray) {
							this.userSitesDropDownArray.push({
								'id': site.siteId,
								'text': site.siteURL,
							});
						}
						this.userSitesArray = sortedSitesArray;
						this.filterSitesArray = sortedSitesArray;
					}
					this.isFetchingRecordsFromServer = false;
					this.selectUserSiteChangeEvent(this.$root.appSiteObject.siteId)
					this.fetchCountStatisticsItems();
				});
			},
			selectDateTypeChangeEvent(val) {
				this.dateTypeValue = val;
				/* TO CHECK CUSTOM DATE OR NOT : START */
				if (this.dateTypeValue == 'custom_date') {
					this.customDateTypeFilter = '';
				} else {
					this.fetchCurrentFilterRecords();
				}
				/* TO CHECK CUSTOM DATE OR NOT : END */

				/* STORE FILTER IN VUEX AND DB : START */
				this.$root.setDefaultFilterElements({
					page: 'reportSummary',
					filterObject: {
						'search_id': val
					},
				});
				/* STORE FILTER IN VUEX AND DB : END */
			},
			changeCustomDateEvent() {
				if (this.customDateTypeFilter.start && this.customDateTypeFilter.end) {
					this.fetchCountStatisticsItems();
				} else {
					this.customDateTypeFilter = '' /* IF ANY START OR END DATE IS EMPTY */
				}
			},
			selectUserSiteChangeEvent(siteId) {
				if (siteId) {
					this.filterSitesArray = _.filter(this.userSitesArray, (item) => { /* FOR FILTER SITES WITH SEARCH */
						if (item.siteId == siteId) {
							return true;
						}
						return false;
					});
				} else {
					this.filterSitesArray = this.userSitesArray;
				}
				this.fetchCountStatisticsItems();
			},
			fetchCurrentFilterRecords() {
				this.fetchCountStatisticsItems();
			},
			fetchCountStatisticsItems() {

				this.$emit('selectedFilter', {
					'sites': this.filterSitesArray,
					'selectedSite': this.selectedSite ?? '',
					'date_type': this.dateTypeValue,
					'date_type_custom_filter': JSON.stringify(this.customDateTypeFilter),
					'sitesLength' : this.filterSitesArray.length,
				});

			},

			/**
			* GET FILTER FROM VUEX AND SET INTO ELEMENTS
			*/
			getDefaultFilterElements() {
				let searchIDFilter = this.$store.getters['appModuleFilters/getFilter']({
					page: 'reportSummary',
					filterKey: 'search_id',
				});
				/* FETCH FILTER FROM VUEX : END */

				this.dateTypeValue = searchIDFilter.date_type ? searchIDFilter.date_type : 'today';
			}
		},
		setup() {

			const {
				dateTypeOptions,
			} = dateTypeListOptions()

			return {
				dateTypeOptions
			}
		},
	}
</script>